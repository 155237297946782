import { PURGE } from 'redux-persist';
import { createSlice } from '@reduxjs/toolkit';
import { getOfferGbnStr } from 'utils/common';
import { TOffers } from '../../@types';

const initialState = {
  offers: [],
  offeringsSeq: null,
  importOffers: [],
  importOffersScList: [],
};

/**
 * @description offer slice
 * @Class
 * @category redux
 * @subcategory slice
 */
const offerSlice = createSlice({
  name: 'offer',
  initialState,
  reducers: {
    setOfferingsSeq: (state, action) => {
      state.offeringsSeq = action.payload.offeringsSeq;
    },
    setImportOffers: (state, action) => {
      state.importOffers = convertImportOffers(action.payload.importOffers);
    },
    setImportOffersScList: (state, action) => {
      state.importOffersScList = convertImportOffersScList(action.payload.importOffersScList);
    },
  },

  extraReducers: (builder) => {
    builder.addCase(PURGE, () => initialState);
  },
});

const convertImportOffers = (data: TOffers.TImportOffers[]): any => {
  return data.map((item: TOffers.TImportOffers) => {
    const targetSourceNameText = item.targetSourceName === 'mst' ? '매물장' : '매물광고';
    return {
      ...item,
      targetSourceNameText,
    };
  });
};

const convertImportOffersScList = (data: TOffers.TImportOffersScList[]): any => {
  return data.map((item: TOffers.TImportOffersScList) => {
    const { acDongNm, areaCd, complexCd, offeringsGbn, cmComplexNm } = item;

    let label;
    if (offeringsGbn === 'VL') {
      label = [getOfferGbnStr(offeringsGbn), '>', acDongNm].join(' ').trim();
    } else {
      label = [getOfferGbnStr(offeringsGbn), '>', acDongNm, cmComplexNm].join(' ').trim();
    }

    const dataSet = {
      'data-sc-area-cd': areaCd,
      'data-sc-complex-cd': complexCd,
      'data-sc-offerings-gbn': offeringsGbn,
    };
    return {
      ...item,
      label,
      dataSet: { ...dataSet },
    };
  });
};

export const { setOfferingsSeq, setImportOffers, setImportOffersScList } = offerSlice.actions;

export default offerSlice;
